import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { Ticket } from '@app-core/models';
import moment from 'moment/moment';

const airplane_takeoff = '/assets/images/airplane-takeoff.svg';
const airplane_landing = '/assets/images/airplane-landing.svg';
const suitcase = '/assets/images/suitcase_icon.svg';
const handbag = '/assets/images/handbag.svg';

@Component({
  selector: 'app-ticket-card',
  templateUrl: './ticket-card.component.html',
  styleUrls: ['./ticket-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketCardComponent {
  ticket = input.required<Ticket>();
  showPrice = input<boolean>(true);
  showBtn = input<boolean>(true);

  variantLink = computed(() => {
    const ticket = this.ticket();
    return `/booking/${ticket.variantId}/${ticket.requestId}`;
  });

  airplaneTakeoff = airplane_takeoff;
  airplaneLanding = airplane_landing;
  suitcase = suitcase;
  handbag = handbag;

  isMoreOneDay(firstDate: number, secondDate: number): number {
    const first = moment.unix(firstDate).utc();
    const second = moment.unix(secondDate).utc();
    return second.isAfter(first, 'day')
      ? Math.ceil(second.diff(first, 'days', true))
      : 0;
  }
}
